import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import TaleNavBar from '../../components/taleNavbar/TaleNavBar.js';


import YTLogo2d from "../../images/logos2d/yt_logo2d_transparent512.png"

const CharacterNew = () => {
    // Navigation related stuff.
    const navigate = useNavigate();

    const onEnter = () => {
        navigate('/waitlist');
    };

    // Attach function to onEnter key handler.
    useEffect(() => {
        const handleEnter = (event) => {
           if (event.keyCode === 13) {
            onEnter();
          }
        };
        window.addEventListener('keydown', handleEnter);
    
        return () => {
          window.removeEventListener('keydown', handleEnter);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return (
        <div>
          { /* Navigation bar */}
          <TaleNavBar onClick={() => navigate('/characterSelector')} >
            <Link to="/characterSelector">Characters</Link>
            Generate a new character
          </TaleNavBar>

          <div style={{textAlign: "center", cursor: "pointer"}} onClick={onEnter} >
            <img src={YTLogo2d} alt="YTLogo2d" style={{width: "100px", padding: "20px"}}/>
            <p>We are currently adding this functionality!</p>
            <p>Interested?</p>
            <p>Press <b>Enter ↵</b> to join the waitlist!</p>
          </div>            
        </div>
    );
};

export default CharacterNew;