import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { CharacterContext } from '../../App.js';
import TaleNavBar from '../../components/taleNavbar/TaleNavBar.js';
import BackgroundImage from '../../components/backgroundImage/BackgroundImage';

import './CharacterSelector.css';
import Logos3d from '../../images/Logos3d.js';

import characters from '../../data.json';


function CharacterSelector(){
    // Get the character & setter from context.
    const { setCharacter } = useContext(CharacterContext)

    // Get the logo for new character.
    const randomLogo3d = Logos3d[Math.floor(Math.random() * Logos3d.length)];

    // Navigation related stuff.
    const navigate = useNavigate();
    
    return (
        <div>
            { /* Navigation bar */}
            <TaleNavBar onClick={null} >
                Pick your character
            </TaleNavBar>

            { /* Character selection panel */}
            <div className="character-selector">
                {characters.map((item, index) => {
                    return (
                        <BackgroundImage src={item.image} title={item.name} description={item.short} onClick={() => [setCharacter(item), navigate('/characterPanel')]}/>
                    )
                })}

                { /* New character! */}
                <BackgroundImage src={randomLogo3d} title="New Character" description="Generate your character" onClick={() => [navigate('/characterNew')]}/>

            </div>
        </div>
    );
};

export default CharacterSelector;