import React, { useEffect }  from 'react';
import { useNavigate } from 'react-router-dom';

import yt_book_castle from "./yt_book_castle.png"

import './Welcome.css';

function Welcome(){
  const navigate = useNavigate();

  const navigateToTales = () => {
    navigate('/characterSelector');
  };

  // Enter -> to tales!
  useEffect(() => {
    const handleEnter = (event) => {
       if (event.keyCode === 13) {
        navigate('/characterSelector');
      }
    };
    window.addEventListener('keydown', handleEnter);

    return () => {
      window.removeEventListener('keydown', handleEnter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <div className="div-welcome">
        <p>Welcome to</p>
        <p style={{'font-size': '50px'}} >You<b>Tale</b></p>
      </div>
      
      <div className="div-enter" onClick={navigateToTales}>
        <img src={yt_book_castle} alt="YouTale" className="hovered-border-img" />

        <div>
          <p>Your personal storyteller</p>
          <p>Click or press <b>Enter ↵</b> to start</p>
        </div>
      </div>
    </>
  )
}

export default Welcome;