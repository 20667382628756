import React, { useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { CharacterContext } from '../../App.js';
import TaleNavBar from '../../components/taleNavbar/TaleNavBar.js';

import './TalePanel.css';

const TalePanel = () => {
    const context = React.useContext(CharacterContext)
    if(context === undefined) {
      throw new Error('The useBanana hook must be used within a CharacterContext.Provider')
    }

    // Get the character & setter from context.
    const { character, tale } = useContext(CharacterContext)

    // Navigation related stuff.
    const navigate = useNavigate();

    useEffect(() => {
        const handleEsc = (event) => {
           if (event.keyCode === 27) {
            navigate('/taleSelector');
          }
        };
        window.addEventListener('keydown', handleEsc);
    
        return () => {
          window.removeEventListener('keydown', handleEsc);
        };
        // eslint-disable-next-line
      }, []);    

    return (
        <div>
            { /* Navigation bar */}
            <TaleNavBar onClick={() => navigate('/taleSelector')} >
                <Link to="/characterSelector">Characters</Link>
                { character && tale && <> 
                        <Link to="/characterPanel" >{character.name}</Link>
                        <Link to="/taleSelector" >Tales</Link>
                        {tale.title}
                    </>
                }
            </TaleNavBar>

            { character && tale && <> 
                { /* Tale panel */}
                <div className='tale-panel'>

                    { /* Image */}
                    <div className="tale-panel-left">
                        <img src={tale.image} alt={tale.title} className="simple-border-img"/>
                    </div>

                    { /* Tale */}
                    <div className="tale-panel-right">
                        <h3 textAlign="center">{tale.subtitle}</h3>
                        {tale.description.map(paragraph =>
                        <p>
                            {[<br />, paragraph, <br />]}
                        </p>
                        )}

                    </div>
                </div>
            </> }
        </div>
    );
};

export default TalePanel;