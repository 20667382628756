import React, { useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { CharacterContext } from '../../App.js';
import TaleNavBar from '../../components/taleNavbar/TaleNavBar.js';
import BackgroundImage from '../../components/backgroundImage/BackgroundImage';

import './TaleSelector.css';
import Logos3d from '../../images/Logos3d.js';
import Logos2d from '../../images/Logos2d';

const TaleSelector = () => {
    // Get the character & setter from context.
    const { character, setTale } = useContext(CharacterContext)

    // Get the logo for new tale.
    const randomLogo3d = Logos3d[Math.floor(Math.random() * Logos3d.length)];

    // Navigation related stuff.
    const navigate = useNavigate();

    // Safe checks.
    if (character) {
        navigate('/characterSelector');
    } 

    useEffect(() => {
        const handleEsc = (event) => {
           if (event.keyCode === 27) {
            navigate('/characterPanel');
          }
        };
        window.addEventListener('keydown', handleEsc);
    
        return () => {
          window.removeEventListener('keydown', handleEsc);
        };
        // eslint-disable-next-line
      }, []);


    // Make sure all tales have an image.
    Object.keys(character.tales).forEach(function(key) {
        if (!character.tales[key].hasOwnProperty("image")) {
            character.tales[key]["image"] = Logos2d[Math.floor(Math.random() * Logos2d.length)];
        }
    });

    return (
        <div>
            { /* Navigation bar */}
            <TaleNavBar onClick={() => navigate('/characterPanel')} >
                <Link to="/characterSelector">Characters</Link>
                <Link to="/characterPanel" >{character.name}</Link>
                Tales
            </TaleNavBar>

            <div className="tale-selector">
                {
                    Object.entries(character.tales).map( ([key, value]) =>
                        <BackgroundImage src={character.tales[key].image} title={character.tales[key].title} description={character.tales[key].subtitle}
                            onClick={() => [setTale(value), navigate('/talePanel')]}/>
                    )
                }

                { /* New tale! */}
                <BackgroundImage src={randomLogo3d} title="Next tale" description="Generate next tale" onClick={() => [navigate('/taleNew')]}/>


            </div>

        </div>
    );
};

export default TaleSelector;