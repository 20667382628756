import React from "react";
import { MdMenuBook } from 'react-icons/md';
import { AiFillHome, AiOutlineForm } from 'react-icons/ai';
import { BsFillInfoCircleFill } from 'react-icons/bs';


export const SidebarData = [
    {
        title: 'Welcome',
        path: '/',
        icon: <AiFillHome />,
        cName: 'nav-text'
    },
    {
        title: 'Tales',
        path: '/characterSelector',
        icon: <MdMenuBook />,
        cName: 'nav-text'
    },
    { 
        title: 'About',
        path: '/about',
        icon: <BsFillInfoCircleFill />,
        cName: 'nav-text'
    },

    { 
        title: 'Waitlist',
        path: '/waitlist',
        icon: <AiOutlineForm />,
        cName: 'nav-text'
    },
]
