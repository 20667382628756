import React from 'react'
import { Routes as RRoutes, Route } from 'react-router-dom'

import Welcome from '../../pages/welcome/Welcome'
import About from '../../pages/about/About'
import Error from '../../pages/error/Error'
import Waitlist from '../../pages/waitlist/Waitlist'

import CharacterNew from '../../pages/characterNew/CharacterNew'
import CharacterSelector from '../../pages/characterSelector/CharacterSelector'
import CharacterPanel from '../../pages/characterPanel/CharacterPanel'

import TaleSelector from '../../pages/taleSelector/TaleSelector'
import TalePanel from '../../pages/talePanel/TalePanel'
import TaleNew from '../../pages/taleNew/TaleNew'

const Routes = () => {

    return (
    <RRoutes>
        <Route exact path="/" element={<Welcome/>} />
        <Route exact path="/about" element={<About/>} />
        <Route exact path="/waitlist" element={<Waitlist/>} />
        <Route path="*" element={<Error/>} />

        <Route exact path="/characterSelector" element={<CharacterSelector/>} />
        <Route exact path="/characterPanel" element={<CharacterPanel/>} /> 
        <Route exact path="/characterNew" element={<CharacterNew/>} />

        <Route exact path="/taleSelector" element={<TaleSelector/>} />
        <Route exact path="/talePanel" element={<TalePanel/>} />
        <Route exact path="/taleNew" element={<TaleNew/>} />
    </RRoutes>
    );
};

export default Routes;