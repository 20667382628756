import React, { Component } from 'react'

import "./BackgroundImage.css"


export default class BackgroundImage extends Component {
  render() {
    return (
      <div class="img-box">
          <img src={this.props.src} onClick={this.props.onClick}/>
          <h1 onClick={this.props.onClick}>{this.props.title}</h1>
          <h2 onClick={this.props.onClick}>{this.props.description}</h2>
      </div>
    )
  }
}

