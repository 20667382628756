import React from 'react'
import Logos3d from '../../images/Logos3d';
import YTLogo2d from "../../images/logos2d/yt_logo2d_transparent512.png"

import "./About.css"


function About() {
  const randomLogo3d = Logos3d[Math.floor(Math.random() * Logos3d.length)];

  return (
    <div className='about' >

      <div className='about-left'>
        <img src={randomLogo3d} alt="Logo3d" style={{width: "100%", maxWidth: "500px"}} />
      </div>
      
      <div className='about-right'>
        <div>
          <p style={{'font-size': '30px'}}>You<b>Tale</b></p>
          <p>Your personal storyteller.</p>
          <p>Immersive and interactive tales.</p>
          <p>Growing and evolving with you.</p>
        </div>

        <div>          
          <img src={YTLogo2d} alt="YTLogo2d" style={{width: "100px", padding: "20px"}} />
        </div>

        <div>
          <p style={{'font-size': '30px'}}>About us</p>
          <p>YouTale AI, Inc. conducts research on multi-modal machine learning,
            neural storytelling and generative AI.
            We focus on development of artificial intelligence-based methods and
            tools for creating consistent and interactive tales.</p>
        </div>
      </div>

    </div>
  )
}

export default About