import React from 'react';
import { Link } from 'react-router-dom';

import "./Footer.css"

import {FaLinkedin, FaTwitterSquare} from 'react-icons/fa';
import { MdEmail } from "react-icons/md";

const Footer = props => {
    return (
        <div className='footer'>
                <ul className="footer-menu">
                    <li>
                        © 2023 YouTale AI
                    </li>
                    <li>
                        <Link to="mailto:contact@youtale.ai">
                            < MdEmail />
                        </Link>
                    </li>
                    <li>
                        <Link to="https://www.linkedin.com/company/youtaleai/">
                            < FaLinkedin />
                        </Link>
                    </li>
                    <li>
                        <Link to="https://twitter.com/YouTaleAI">
                            < FaTwitterSquare />
                        </Link>
                    </li>
                </ul>            
        </div>
    );
};

export default Footer;