import React from 'react'
import { BrowserRouter } from 'react-router-dom';

import Header from './components/header/Header';
import Routes from './components/routes/Routes';
import Footer from "./components/footer/Footer";

import './App.css'

export const CharacterContext = React.createContext();

export default function App() {

  // Create the state and setter for the character.
  const [character, setCharacter] = React.useState(null);
  const [tale, setTale] = React.useState(null);

  return (
    <div className='page-container'>
      <CharacterContext.Provider value={{ character, setCharacter, tale, setTale}}>

          <BrowserRouter>
            <Header />
            <div className='content-wrap'>
            <Routes />
            </div>
            <Footer />
          </BrowserRouter>

      </CharacterContext.Provider>
    </div>
  );
}
