import React, { useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { CharacterContext } from '../../App.js';
import TaleNavBar from '../../components/taleNavbar/TaleNavBar.js';

import './CharacterPanel.css';

import YTLogo2d from "../../images/logos2d/yt_logo2d_transparent512.png"


const CharacterPanel = () => {
    // Navigation related stuff.
    const navigate = useNavigate();
    navigate('/characterSelector');

    // Get the character & setter from context.
    const { character } = useContext(CharacterContext)


    // Safe checks.
    if (character === null) {
      navigate('/characterSelector');
    } 

    let tales_to_explore = (Object.keys(character.tales).length > 0);

    const onEnter = () => {
      // Depending on the tales.
      if (!tales_to_explore) {
        navigate('/waitlist');
      } else {
        navigate('/taleSelector');
      }
    };

    // Attach function to onEnter key handler.
    useEffect(() => {
        const handleEnter = (event) => {
           if (event.keyCode === 13) {
            onEnter();
          }
        };
        window.addEventListener('keydown', handleEnter);
    
        return () => {
          window.removeEventListener('keydown', handleEnter);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
    useEffect(() => {
        const handleEsc = (event) => {
           if (event.keyCode === 27) {
            navigate('/characterSelector')
          }
        };
        window.addEventListener('keydown', handleEsc);
    
        return () => {
          window.removeEventListener('keydown', handleEsc);
        };
        // eslint-disable-next-line
      }, []);
    
    return (
      <div>
        { /* Navigation bar */}
        <TaleNavBar onClick={() => navigate('/characterSelector')} >
          <Link to="/characterSelector">Characters</Link>
          {character.name}
        </TaleNavBar>

        { /* Character panel */}
        <div className='character-panel'>

          { /* Image */}
          <div className="character-panel-left">
            <img src={character.image} alt={character.name} className="simple-border-img"/>
          </div>

          { /* Description */}
          <div className="character-panel-right">

            {character.description.map(paragraph =>
              <p>
                  {[<br />, paragraph, <br />]}
              </p>
            )}


            { /* No tales to explore! -> Waitlist */
              !tales_to_explore &&
              <div style={{textAlign: "center", cursor: "pointer"}} onClick={onEnter} >
                <img src={YTLogo2d} alt="YTLogo2d" style={{width: "100px", padding: "20px"}}/>
                <p>Sadly, there are no {character.name} tales to explore as the very moment. Press <b>Enter ↵</b> to join the waitlist!</p>
              </div>
            }

            { /* Tales Panel */
              tales_to_explore &&
              <div style={{textAlign: "center", cursor: "pointer"}} onClick={onEnter} >
                <img src={YTLogo2d} alt="YTLogo2d" style={{width: "100px", padding: "20px"}}/>
                <p>There are {Object.keys(character.tales).length} {character.name} tales to explore! Press <b>Enter ↵</b> to start.</p>
              </div>
            }



          </div>
        </div>                

      </div>
    );
};

export default CharacterPanel;