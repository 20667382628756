import React from 'react'
import { Link } from 'react-router-dom';

import TaleNavBar from '../../components/taleNavbar/TaleNavBar.js';
import Families from '../../images/Families';

function Waitlist() {
  const randomFamily = Families[Math.floor(Math.random() * Families.length)];

  return (

    <>
      { /* Navigation bar */}
      <TaleNavBar >
        <Link to="/characterSelector">Home</Link>
      </TaleNavBar>

      <div style={{alignItems: "center", justifyContent: "center", display: 'flex', flexDirection: "column"}}>
        <img src={randomFamily} alt="YouTaleFamily" style={{width: "100%", maxWidth: "640px"}} />

        <iframe
          title="Waitlist"
          src="https://docs.google.com/forms/d/e/1FAIpQLSczRrQIyZDdguC-ZABFWNo9ozgxsnFNRE5xkvhOtl5WZPwXVg/viewform?embedded=true"
          width="100%"
          maxWidth="100px"
          height="600"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        />
      </div>
    </>

  );
}

export default Waitlist