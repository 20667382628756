import React from 'react';

import { AiOutlineClose } from 'react-icons/ai';

import './TaleNavBar.css';

function TaleNavBar(props) {
    return (
        <div className='talenavbar'>

          <div className='talenavbar-left'>
            <ul className='talenavbar-breadcrumbs'>
              {React.Children.map(props.children, child => (
                <li>{child}</li>
              ))}
            </ul>

          </div>

          { props.onClick &&
          <div className='talenavbar-right'>
            <AiOutlineClose onClick={props.onClick}/>
          </div>
          }
        </div>

    );
};

export default TaleNavBar;