import React from 'react';
import { Link } from 'react-router-dom';

import TaleNavBar from '../../components/taleNavbar/TaleNavBar.js';
import Families from '../../images/Families';
import './Error.css';



function Error(){
  const randomFamily = Families[Math.floor(Math.random() * Families.length)];

  return (
    <>
      { /* Navigation bar */}
      <TaleNavBar >
        <Link to="/characterSelector">Home</Link>
      </TaleNavBar>

      <div className='error'>
        <img src={randomFamily} alt="YouTale Family" className="simple-border-img" />
        <p style={{'font-size': '20px'}}>Sorry, the requested page doesn't exist...</p>
      </div>
    </>
  )
}

export default Error;